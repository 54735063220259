<template>
  <b-row>
    <b-col cols="12 col-md-4 col-sm-4">
      <b-row>
        <b-col cols="12">
          <div class="card border bg-gradient-danger">
            <div class="card-body p-1">
              <h5 class="card-title"><strong class="text-white">Commission : {{ result.commission }} %</strong></h5>
              <h5><strong class="text-white">ยอดโบนัส : {{ func.toMoney(result.bonus) }} บาท</strong></h5>
              <p class="card-text">
                <router-link v-if="select_page == 'commission'" :to="'/agent'" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="ChevronsLeftIcon" class="font-medium-1 mr-1" /></span>กลับหน้ารายการ</router-link>
                <router-link v-else :to="'/agent/commission/'+page" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="GiftIcon" class="font-medium-1 mr-1" /></span>รายงานคอมมิชชั่น</router-link>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="card border bg-gradient-success">
            <div class="card-body p-1">
              <h5 class="card-title"><strong class="text-white">สรุปฝาก-ถอนยูส</strong></h5>
              <h5><strong class="text-white">ฝาก {{ func.toMoney(result.deposit) }} บาท | ถอน {{ func.toMoney(result.withdraw) }} บาท</strong></h5>
              <p class="card-text">
                <router-link v-if="select_page == 'report_member'" :to="'/agent'" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="ChevronsLeftIcon" class="font-medium-1 mr-1" /></span>กลับหน้ารายการ</router-link>
                <router-link v-else :to="'/agent/report_member/'+page" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="BookIcon" class="font-medium-1 mr-1" /></span>รายงานฝาก-ถอน</router-link>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12 col-md-4 col-sm-4">
      <b-row>
        <b-col cols="12">
          <div class="card border bg-gradient-warning">
            <div class="card-body p-1">
              <h5 class="card-title"><strong class="text-white">Agent : ยูสเซอร์ ({{ result.register_user }})</strong></h5>
              <h5><strong class="text-white">เครดิตเอเย่น : {{ func.toMoney(result.balance) }} บาท</strong></h5>
              <p class="card-text">
                <router-link v-if="select_page == 'member'" :to="'/agent'" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="ChevronsLeftIcon" class="font-medium-1 mr-1" /></span>กลับหน้ารายการ</router-link>
                <router-link v-else :to="'/agent/member/'+page" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="UsersIcon" class="font-medium-1 mr-1" /></span>ยูสของเอเย่น</router-link>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="card border bg-gradient-info">
            <div class="card-body p-1">
              <h5 class="card-title"><strong class="text-white">Winlost : แพ้ชนะ</strong></h5>
              <h5><strong class="text-white">ยอดได้เสีย : {{ (result.fee > result.transfer ? '-' + func.toMoney(result.fee) : func.toMoney(result.transfer)) }} บาท</strong></h5>
              <p class="card-text">
                <router-link v-if="select_page == 'report_winlost'" :to="'/agent'" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="ChevronsLeftIcon" class="font-medium-1 mr-1" /></span>กลับหน้ารายการ</router-link>
                <router-link v-else :to="'/agent/report_winlost/'+page" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="ColumnsIcon" class="font-medium-1 mr-1" /></span>รายงานบัญชีแพ้ชนะ</router-link>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12 col-md-4 col-sm-4">
      <b-row>
        <b-col cols="12">
          <div class="card border bg-gradient-primary">
            <div class="card-body p-1">
              <h5 class="card-title"><strong class="text-white">Agent : {{ result.prefix }} ({{ result.full_name }})</strong></h5>
              <h5><strong class="text-white">ยูสภายใต้รวมทั้งหมด : {{ func.toNum(result.register_count || 0) }} คน</strong></h5>
              <p class="card-text">
                <router-link v-if="select_page == 'report_member_register'" :to="'/agent'" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="ChevronsLeftIcon" class="font-medium-1 mr-1" /></span>กลับหน้ารายการ</router-link>
                <router-link v-else :to="'/agent/report_member_register/'+page" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="UsersIcon" class="font-medium-1 mr-1" /></span>ยูสเซอร์ภายใต้เอเย่นต์</router-link>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="card border bg-gradient-dark">
            <div class="card-body p-1">
              <h5 class="card-title"><strong class="text-white">UFABET : Login</strong></h5>
              <h5><strong class="text-white">username : {{ result.username }} | password : {{ 'x'.repeat(result.password.length) }}</strong></h5>
              <p class="card-text">
                <router-link v-if="select_page == 'login'" :to="'/agent'" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="ChevronsLeftIcon" class="font-medium-1 mr-1" /></span>กลับหน้ารายการ</router-link>
                <router-link v-else :to="'/agent/login/'+page" class="btn-block btn btn-white waves-effect waves-light"><span><feather-icon icon="LogInIcon" class="font-medium-1 mr-1" /></span>เข้าสู่ระบบ</router-link>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import func from '@core/comp-functions/misc/func'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
  },
  data() {
    return {
      func: func,
      page: this.$route.params.id,
      PATH: '/agent',
      BASE_URL: process.env.BASE_URL,
      API_GET_URL: 'agent/agent_detail/' + this.$route.params.id,
      result: {
        prefix: '',
        full_name: '',
        username: '',
        password: '',
        deposit: '',
        withdraw: '',
        commission: '',
        register_user: '',
        register_count: '',
        balance: '',
        transfer: '',
        bonus: '',
        fee: '',
        role: '',
      },
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.$http.get(this.API_GET_URL).then(res => { 
        if (res.data != null && res.data.result) {
          this.result = res.data
          this.$emit('prefix', this.result.prefix)
        }
        else this.$router.push(this.PATH)
      }).catch(()=>this.$router.push(this.PATH))
    },
  },
  props: {
    select_page: {
      type: String,
      default: '',
    },
  },
}
</script>

